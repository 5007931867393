import React from 'react';

import './Input.css';

const Input = ({ setMessage, sendMessage, message, sendAction,messageVisible,playVisible,crosscheckVisible }) => (
  <form className="form">
    <input
      className={`input ${messageVisible ? "" : "hiddenclass"}`}
      type="text"
      placeholder="Message à envoyer..."
      value={message}
      onChange={({ target: { value } }) => setMessage(value)}
      onKeyPress={event => event.key === 'Enter' ? sendMessage(event) : null}
    />

    <button className={`baseButton checkButton ${crosscheckVisible ? "" : "hiddenclass"}`} onClick={e => sendAction(e,'ok')}><span role="img" aria-label="emoji">👍</span></button>
    <button className={`baseButton crossButton ${crosscheckVisible ? "" : "hiddenclass"}`} onClick={e => sendAction(e,'pass')}><span role="img" aria-label="emoji">❌</span></button>
    
    <button className={`baseButton sendButton ${messageVisible ? "" : "hiddenclass"}`} onClick={e => sendMessage(e)}>Envoi</button>
    <button className={`baseButton playButton ${playVisible ? "" : "hiddenclass"}`} onClick={e => sendAction(e,'play')}>Jouer</button>
  </form>
)

export default Input;