import React from 'react';

import onlineIcon from '../../icons/onlineIcon.png';

import './TextContainer.css';

const TextContainer = ({ users, room }) => {

 
  return(
    
  <div className="textContainer">
    <div>
      <div className="center">
        <h1 className="heading">Chronotos<div className="beta">beta 0.2</div> </h1> 
      </div>
      <h4>Lien pour inviter des joueurs à la partie : </h4>
      <a href={`https://chronotos.logitos.fr?room=${room}`}>https://chronotos.logitos.fr?room={room}</a>
      
    </div>
    {
      users
        ? (
          <div>
            <h1>Les équipes :</h1>
            <h2>
            <div className="activeContainer">
              
                {users.map(({name, team}) => (
                  <div key={name} className="activeItem">
                    {name} ({team})
                  </div>
                ))}              
            </div>
            </h2>
          </div>
        )
        : null
    }
  </div>
);
  }
export default TextContainer;