import React from 'react';

import ScrollToBottom from 'react-scroll-to-bottom';
import Iframe from 'react-iframe'

import Message from './Message/Message';

import './Messages.css';

const Messages = ({ messages, setMessages, name, room, users, langueVisible, mancheVisible, sendAction, setLangueVisible, setMancheVisible, audioOK, browserInfo}) => {

  return (
  <ScrollToBottom className="messages">
    <div className="messageBox">
      <div className="pl-10 messageText colorDark">
      Avant de démarrer la partie, appelez-vous via l'appli de votre choix <b>ou</b> utiliser le bouton ci dessous :
      </div>
    </div> 
    {audioOK && <Iframe url={`https://logitos.fr/conf.html?room=ct${room}`}
        allow="microphone"
        width="100%"
        height="50px"
        frameBorder="0"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/>
    }
    {!audioOK &&     <div className="messageBox">
      <div className="pl-10 messageText colorDark">
      Votre navigateur ({browserInfo}) n'est pas comptaible avec les appels integrés dans l'appli.
      </div>
    </div> 
    }
    <div className="messageBox">
      <div className="pl-10 messageText colorDark">
        <a target="_blank" href={`https://chronotos.logitos.fr?room=${room}`}>Lien pour inviter des joueurs à la partie</a>
      </div>
    </div> 
    <div className="messageBox">
      <div className="pl-10 messageText colorDark">
      Dès que tous les joueurs sont connectés, appuyer sur <b>Jouer</b> pour démarrer la partie ! 
      40 secondes pour faire deviner les mots aux membres de votre équipe !
      </div>
    </div> 
    
     {messages.map((message, i) => <div key={i}><Message message={message} name={name}/></div>)}
     <button className={`baseButton  nouvelleManche langueButton ${mancheVisible ? "" : "hiddenclass"}`} onClick={e => {setMessages([]);setMancheVisible(false);setLangueVisible(false); sendAction(e,'nouvelleManche')}}>Nouvelle manche <br />(mots identiques)</button>
     <button className={`baseButton  nouvellePartie langueButton ${langueVisible ? "" : "hiddenclass"}`} onClick={e => {setLangueVisible(false);setMancheVisible(false); sendAction(e,'personnalites')}}>Nouvelle partie <br />Personnalités</button>
     <button className={`baseButton  nouvellePartie langueButton ${langueVisible ? "" : "hiddenclass"}`} onClick={e => {setLangueVisible(false);setMancheVisible(false); sendAction(e,'french_easy')}}>Nouvelle partie <br />Mots faciles</button>
    <button className={`baseButton  nouvellePartie langueButton ${langueVisible ? "" : "hiddenclass"}`} onClick={e => {setLangueVisible(false);setMancheVisible(false); sendAction(e,'english')}}>Nouvelle partie <br />Anglais facile</button>
    <button className={`baseButton  nouvellePartie langueButton ${langueVisible ? "" : "hiddenclass"}`} onClick={e => {setLangueVisible(false);setMancheVisible(false); sendAction(e,'german')}}>Nouvelle partie <br />Allemand facile</button>
    <button className={`baseButton nouvellePartie  langueButton ${langueVisible ? "" : "hiddenclass"}`} onClick={e => {setLangueVisible(false);setMancheVisible(false); sendAction(e,'italian')}}>Nouvelle partie <br />Italien facile</button>
    <button className={`baseButton  nouvellePartie langueButton ${langueVisible ? "" : "hiddenclass"}`} onClick={e => {setLangueVisible(false);setMancheVisible(false); sendAction(e,'spanish')}}>Nouvelle partie <br />Espagnol facile</button>
    <button className={`baseButton nouvellePartie  langueButton ${langueVisible ? "" : "hiddenclass"}`} onClick={e => {setLangueVisible(false);setMancheVisible(false); sendAction(e,'disney')}}>Nouvelle partie <br />Disney</button>

  </ScrollToBottom>
  )
};

export default Messages;