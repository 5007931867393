import React, { useState, useEffect } from "react";
import queryString from 'query-string';
import io from "socket.io-client";

import TextContainer from '../TextContainer/TextContainer';
import Messages from '../Messages/Messages';
import InfoBar from '../InfoBar/InfoBar';
import Input from '../Input/Input';

import './Chat.css';

const { detect } = require('detect-browser');
const browser = detect();
let audioOK=true;
let browserInfo;
if (browser) {
  browserInfo=browser.name+';'+browser.version.split(".")[0]+";"+browser.os;
  
  if(browser.name=="edge" && browser.version.split(".")[0]<50){
    audioOK=false;
  }
}

//const ENDPOINT = 'http://192.168.1.40:5000';
 //const ENDPOINT = 'https://test.logitos.fr:5001';
const ENDPOINT = 'https://test.logitos.fr:5000';
const socket = io(ENDPOINT,{});

const Chat = ({ location }) => {
  const [name, setName] = useState('');
  const [room, setRoom] = useState('');
  const [users, setUsers] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [team, setTeam] = useState('');
  const [timerValue, setTimerValue] = useState('');
  const [playVisible, setPlayvisible] = useState(false);
  const [messageVisible, setMessageVisible] = useState(true);
  const [crosscheckVisible, setCrosscheckVisible] = useState(false);
  const [langueVisible, setLangueVisible] = useState(false);
  const [mancheVisible, setMancheVisible] = useState(false);
    

  
  useEffect(() => {
  testConnexion();
}, [ENDPOINT, location.search]);

useEffect(() => {
    socket.on('message', message => {
      //console.log("messsage")
      setMessages(messages => [ ...messages, message ]);

    });

    socket.on('demaragejeu',({demarrage,manche}) => {

      setLangueVisible(demarrage);
      setMancheVisible(manche);
    });

    

    socket.on('jeu', ({playVisible, messageVisible, crosscheckVisible,timerValue}) => {
      //console.log(playVisible)
      setPlayvisible(playVisible);
      setMessageVisible(messageVisible);
      setCrosscheckVisible(crosscheckVisible);
      setTimerValue(timerValue);
    });

    socket.on('disconnect', () => {
     // setMessages(messages => [ ...messages, { user: "admin", text: "Vous êtes parti." } ]);
     //testConnexion();
    });
  
    socket.on('reconnect', () => {
      //setMessages(messages => [ ...messages, { user: "admin", text: "Vous êtes reconnecté." } ]);
     
        testConnexion();
     
    });
    
    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });
  }, []);

const testConnexion = () => {
  const { name, room } = queryString.parse(location.search);

 

  setRoom(room);
  setName(name)

  socket.emit('join', { name, room , browserInfo}, (team, error) => {
    if(team){
      if(team==="Username is taken.")
      window.location.replace(`https://chronotos.logitos.fr?room=${room}`);
      setTeam(team);
    }
  });
}

  const sendMessage = (event) => {
    event.preventDefault();

    if(message) {
      socket.emit('sendMessage', message, () => setMessage(''));
    }
  }

  const sendAction = (event,action) => {
    event.preventDefault();

    if(action) {
      socket.emit('sendAction', action);
      if(action === "ok"){
        messages[messages.length-1].text=messages[messages.length-1].text+" 👍";
      }
      if(action === "pass"){
        messages[messages.length-1].text=messages[messages.length-1].text+" ❌";
      }
      
    }
  }

  return (
    <div className="outerContainer">
      <div className="container">
          <InfoBar room={room} name={name} team={team} timerValue={timerValue} />
          <Messages users={users} browserInfo={browserInfo} audioOK={audioOK} setMessages={setMessages} messages={messages} name={name} room={room} setLangueVisible={setLangueVisible} setMancheVisible={setMancheVisible} sendAction={sendAction} mancheVisible={mancheVisible} langueVisible={langueVisible}/>
          <Input message={message} setMessage={setMessage} sendMessage={sendMessage} sendAction={sendAction} messageVisible={messageVisible} playVisible={playVisible} crosscheckVisible={crosscheckVisible}/>
      </div>
      <TextContainer users={users} room={room}/>
    </div>
  );
}

export default Chat;
